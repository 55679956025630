import { GetServerSideProps, InferGetServerSidePropsType } from "next";

import { AppShell } from "@chef/feature-app-shell";
import { BRAND_PATHS } from "@chef/constants";

import { ICampaign, getCampaignData } from "../queries";
import { ContentComponent } from "../components";
import { SanityQuery } from "../types";
import { useCampaignData } from "../hooks/campaign";

export const CampaignPage = (
  props: InferGetServerSidePropsType<typeof getServerSideProps>,
) => {
  const [campaign] = useCampaignData(props.campaign);

  if (!campaign) {
    return null;
  }

  return (
    <ContentComponent block={campaign.content}>
      {({ children, title, description, imageUrl, index }) => (
        <AppShell
          title={title}
          description={description}
          image={imageUrl}
          index={index}
        >
          <AppShell.Meta />
          <AppShell.Header />
          <AppShell.DiscountBanner />
          <AppShell.Main noPadding>{children}</AppShell.Main>
          <AppShell.Footer />
        </AppShell>
      )}
    </ContentComponent>
  );
};

export const getServerSideProps: GetServerSideProps<
  {
    draftMode: boolean;
    campaign: ICampaign;
  },
  SanityQuery
> = async ({ params = {}, draftMode = false, req }) => {
  const campaign = await getCampaignData(params.slug, { preview: draftMode });

  if (!campaign || !campaign.content) {
    const url = new URL(req.url || "", "http://example.com");

    url.pathname = `${BRAND_PATHS.CAMPAIGN_DIRECTORY}${params.slug}`;
    url.searchParams.set("__rw", "1");

    return {
      redirect: {
        destination: `${url.pathname}${url.search}`,
        statusCode: 301,
      },
    };
  }

  return {
    props: {
      draftMode,
      campaign,
    },
  };
};
